export default function throttle<F extends (...args: unknown[]) => void | Promise<void>>(fn: F, time: number) {
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    let lastArgs: Parameters<F> | undefined = undefined;

    const clear = () => {
        clearTimeout(timer);
        timer = undefined;
        lastArgs = undefined;
    };

    function throttledFn(...args: Parameters<F>) {
        lastArgs = args;
        timer ??= setTimeout(() => {
            Array.isArray(lastArgs) && fn(...lastArgs);
            clear();
        }, time);
    }

    throttledFn.clear = clear;
    return throttledFn;
}
