import React, { useCallback, useMemo } from 'react';

import { Button, SvgIcon } from '@mui/material';

import useStyles from './styles';

import { BaseOAuthProps } from '../../types';

interface Props extends BaseOAuthProps {
    label?: string;
    icon?: React.ReactNode;
    compact?: boolean;
    inverted?: boolean;
    apiUrl: string | null;
}

const BaseOAuthButton: React.FC<Props> = ({
    disabled,
    parameters,
    backTo = '/search',
    apiUrl,
    label,
    icon,
    className,
    compact,
    inverted,
    onClick
}) => {
    const { classes, cx } = useStyles();

    const stringify = useCallback((url: string, parameters: Record<string, string | number | boolean | null>) => {
        const [path, search = ''] = url.split('?');
        const searchParams = new URLSearchParams(search);

        Object.entries(parameters).forEach(([key, value]) => {
            if (value !== undefined) {
                searchParams.set(key, String(value));
            }
        });

        return [path, searchParams.toString()].join('?');
    }, []);

    const href = useMemo(() => {
        return apiUrl ? stringify(apiUrl, { back_to: stringify(backTo, parameters ?? {}) }) : undefined;
    }, [apiUrl, backTo, parameters, stringify]);

    return (
        <Button
            disabled={disabled}
            size={compact ? 'small' : 'medium'}
            fullWidth={!compact}
            onClick={onClick}
            variant="outlined"
            centerRipple
            color="inherit"
            href={href}
            startIcon={
                <SvgIcon className={classes.icon} inheritViewBox>
                    {icon}
                </SvgIcon>
            }
            classes={{
                root: cx(classes.button, { [classes.inverted]: inverted }, className),
                sizeMedium: classes.medium,
                sizeSmall: classes.small
            }}
        >
            {label}
        </Button>
    );
};

export default React.memo(BaseOAuthButton);
