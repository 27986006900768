import React from 'react';

import BaseOAuthButton from './BaseOAuthButton';

import Icon from '../assets/linkedin.svg';
import { BaseOAuthProps } from '../types';

const LinkedInOAuthButton: React.FC<BaseOAuthProps> = (props) => {
    return <BaseOAuthButton {...props} label="Linkedin" apiUrl="/api/v3/oauth/linkedin" icon={<Icon />} />;
};

export default LinkedInOAuthButton;
