import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { TextField as MuiTextField, TextFieldProps } from '@mui/material';

const useStyles = makeStyles()({
    textField: {
        fontSize: 16,
        '& input': {
            borderRadius: 12,
            fontSize: 16,
            color: '#38383A'
        },
        '& .MuiInputBase-root': {
            background: '#F5F6F8'
        },
        '& .MuiOutlinedInput-input': {
            padding: '26px 16px 13px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& label': {
            color: '#979797'
        },
        '& .MuiInputLabel-root': {
            color: '#8385A3',
            fontSize: 16,
            fontWeight: 400
        },
        '& label.MuiFormLabel-filled, label.Mui-focused': {
            top: 18
        }
    },
    input: {
        borderRadius: 18
    },
    inputLabel: {
        paddingLeft: 2
    }
});

const TextField: React.FC<TextFieldProps> = ({ className, ...restProps }) => {
    const { classes, cx } = useStyles();
    return (
        <MuiTextField
            variant="outlined"
            className={cx(classes.textField, className)}
            InputProps={{ className: classes.input }}
            InputLabelProps={{ className: classes.inputLabel }}
            {...restProps}
        />
    );
};

export default TextField;
