import React from 'react';

import BaseOAuthButton from './BaseOAuthButton';

import Icon from '../assets/github.svg';
import { BaseOAuthProps } from '../types';

const GithubOAuthButton: React.FC<BaseOAuthProps> = (props) => {
    return <BaseOAuthButton {...props} label="Github" apiUrl="/api/v3/oauth/github" icon={<Icon />} />;
};

export default GithubOAuthButton;
