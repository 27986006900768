import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    button: {
        color: '#282D31',
        boxShadow: 'none !important',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#ECECEC',
        whiteSpace: 'nowrap'
    },
    inverted: {
        color: '#fff',
        borderColor: 'rgba(255, 255, 255, 0.25)'
    },
    medium: {
        padding: '17px 24px',
        borderRadius: 18,
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '16px'
    },
    small: {
        padding: '14px 16px',
        borderRadius: 12,
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '16px'
    },
    icon: {
        width: 16,
        height: 16,
        overflow: 'visible'
    }
});
