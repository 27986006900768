import { makeStyles } from 'tss-react/mui';

export default makeStyles<void, 'item'>({ name: 'OAuthButtonGroup' })((_, __, classes) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        flex: 1,
        gap: 16
    },
    item: {
        minWidth: 150,
        flex: '1 1 calc(50% - 16px)',
        '@media (min-width: 669px)': {
            maxWidth: 'calc(50% - 16px)'
        }
    },
    compact: {
        [`& .${classes.item}`]: {
            flex: 0
        }
    }
}));
