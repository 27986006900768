import React, { useCallback, useMemo, useRef, useState } from 'react';

import Captcha from 'utils/Captcha';

export default function useLoginCaptcha() {
    const captchaRef = useRef<Captcha | null>(null);
    const [captchaValue, setCaptchaValue] = useState<string>('');

    const captcha = useMemo(
        () => <Captcha id="vulners-login-captcha" ref={captchaRef} action="login" style={{ margin: 'auto' }} />,
        []
    );

    const getCaptcha = useCallback(() => {
        if (captchaValue) {
            return captchaValue;
        } else {
            const newValue = captchaRef.current?.getValue() || '';
            setCaptchaValue(newValue);
            return newValue;
        }
    }, [captchaValue]);

    const resetCaptcha = useCallback(() => {
        captchaRef.current?.resetValue();
        setCaptchaValue('');
    }, []);

    const recoverCaptcha = useCallback(() => captchaRef.current?.recoverCaptchaId(), []);

    return { captcha, getCaptcha, resetCaptcha, recoverCaptcha };
}
