import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import EnterpriseOAuthButton from './components/EnterpriseOAuthButton';
import FacebookOAuthButton from './components/FacebookOAuthButton';
import GithubOAuthButton from './components/GithubOAuthButton';
import GoogleOAuthButton from './components/GoogleOAuthButton';
import LinkedInOAuthButton from './components/LinkedInOAuthButton';
import MicrosoftOAuthButton from './components/MicrosoftOAuthButton';
import useStyles from './styles';
import { BaseOAuthProps } from './types';

interface Props extends BaseOAuthProps {
    google?: boolean;
    microsoft?: boolean;
    github?: boolean;
    facebook?: boolean;
    linkedin?: boolean;
    enterprise?: boolean | ((backTo?: string, parameters?: object) => void);
    compact?: boolean;
    inverted?: boolean;
}

const OAuthButtonGroup: React.FC<Props> = ({
    google = true,
    microsoft = true,
    github = true,
    facebook = true,
    linkedin = true,
    enterprise = true,
    className,
    ...props
}) => {
    const { compact, onClick, backTo, parameters } = props;
    const { classes, cx } = useStyles();
    const history = useHistory();

    const handleEnterpriseSignIn = useCallback(() => {
        if (typeof enterprise === 'function') {
            enterprise(backTo, parameters);
        } else {
            onClick?.();
            history.push('/enterprise-sign-in');
        }
    }, [backTo, enterprise, history, onClick, parameters]);

    return (
        <div className={cx(classes.container, { [classes.compact]: compact }, className)}>
            {google ? <GoogleOAuthButton {...props} className={classes.item} /> : null}
            {microsoft ? <MicrosoftOAuthButton {...props} className={classes.item} /> : null}
            {github ? <GithubOAuthButton {...props} className={classes.item} /> : null}
            {facebook ? <FacebookOAuthButton {...props} className={classes.item} /> : null}
            {linkedin ? <LinkedInOAuthButton {...props} className={classes.item} /> : null}
            {enterprise ? (
                <EnterpriseOAuthButton {...props} className={classes.item} onClick={handleEnterpriseSignIn} />
            ) : null}
        </div>
    );
};

export default OAuthButtonGroup;
