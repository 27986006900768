import React from 'react';

import BaseOAuthButton from './BaseOAuthButton';

import Icon from '../assets/facebook.svg';
import { BaseOAuthProps } from '../types';

const FacebookOAuthButton: React.FC<BaseOAuthProps> = (props) => {
    return <BaseOAuthButton {...props} label="Facebook" apiUrl="/api/v3/oauth/facebook" icon={<Icon />} />;
};

export default FacebookOAuthButton;
