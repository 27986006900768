import { makeStyles } from 'tss-react/mui';

export const COLORS = [
    { color: '#82C4BB', ln: 0.7 },
    { color: '#FACE3A', ln: 0.3 },
    { color: '#FF3306', ln: 0.0 }
];

export const BLANK_COLOR = '#F9F9F9';

export default makeStyles()({
    root: {
        width: '100%'
    },
    strength: {
        marginTop: 8,
        display: 'grid',
        columnGap: 2,
        gridTemplateColumns: 'repeat(4, 1fr)'
    },
    strengthPoint: {
        background: BLANK_COLOR,
        borderRadius: 100,
        height: 8
    },
    input: {
        borderRadius: 18
    }
});
